export default {
  // 打印单公共信息配置
  PUBLIC: [
    [{
      label: '制单人:',
      value: 'createUserName',
      width: 1,
    }, {
      label: '出库仓库:',
      value: 'tenantName',
      width: 1,
    }, {
      label: '单据编号:',
      value: 'deliverOrderNo',
      width: 1,
    }],
    [{
      label: '经手人:',
      value: 'createUserName',
      width: 1,
    }, {
      label: '录单日期:',
      value: 'createTime',
      width: 1,
    }, {
      label: '打印时间:',
      value: 'printTime',
      width: 1,
    }],
    [{
      label: '往来单位:',
      value: 'stationName',
      width: 1,
    }],
    [{
      label: '收货地址:',
      value: 'stationAddress',
      width: 1,
    }],
    [{
      label: '摘要:',
      value: 'remark',
      width: 1,
    }],
  ],

  getTableHeaders() {
    return [
      {
        label: '商品条码', prop: 'shipperGoodsCode', width: '110px',
      },
      {
        label: '商品名称', prop: 'goodsName', width: '130px',
      },
      {
        label: '数量', prop: 'defaultUnitAmount', width: '30px',
      },
      {
        label: '单价', prop: 'goodsPrice', width: '30px',
      },
      { label: '金额', prop: 'totalPrice', width: '30px' },
      { label: '赠送金额', prop: 'presentPrice', width: '60px' },
      { label: '批发价', prop: 'wholeSalePrice', width: '120px' },
      { label: '扣率', prop: 'discountRate', width: '120px' },
    ];
  },
};
