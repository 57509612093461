export default {
  // 打印单公共信息配置
  PUBLIC: [
    [{
      label: '客户编号:',
      value: 'shipperCode',
      width: 1,
    }, {
      label: '客户名称:',
      value: 'shipperName',
      width: 1,
    }, {
      label: '客户单号:',
      value: 'waveOrderNo',
      width: 1,
    }],
    [{
      label: '出库总件数:',
      value: 'totalDefaultUnitAmount',
      width: 1,
    }, {
      label: '出库总吨数:',
      value: 'totalWeight',
      width: 1,
    }, {
      label: '计划出库时间:',
      value: 'planedDeliverTime',
      width: 1,
      textStyle: {
        fontWeight: 400,
      },
    }],
    [{
      label: '开始装车时间:',
      value: 'beginLoadingTime',
      width: 1,
    }, {
      label: '装车完成时间:',
      value: 'endLoadingTime',
      width: 1,
      textStyle: {
        fontWeight: 400,
      },
    }, {
      label: '出库温度:',
      value: '',
      width: 1,
      textStyle: {
        fontWeight: 400,
      },
    }],
    [{
      label: '车牌号/车次:',
      value: 'plateNumber',
      textStyle: {
        fontWeight: 400,
      },
      width: 1,
    }, {
      label: '装车顺序:',
      value: 'loadingOrder',
      textStyle: {
        fontWeight: 400,
      },
      width: 1,
    }, {
      label: '配送点:',
      value: 'stationName',
      textStyle: {
        fontWeight: 400,
      },
      width: 1,
    }],
    [{
      label: '温层:',
      value: 'temperatureLayer',
      textStyle: {
        fontWeight: 400,
      },
      width: 1,
    }, {
      label: '打印时间:',
      value: 'printTime',
      textStyle: {
        fontWeight: 400,
      },
      width: 1,
    }, {
      width: 1,
    }],
  ],

  getTableHeaders(batchAttributes = []) {
    const dynamicList = batchAttributes.map((item) => ({
      label: item.label, width: '100px', prop: item.value, class: 'letter-spacing-1',
    }));
    return [
      {
        label: '商品代码', prop: 'shipperGoodsCode', width: '110px',
      },
      {
        label: '商品名称', prop: 'goodsName', width: '110px',
      },
      {
        label: '商品规格', prop: 'specification', width: '80px',
      },
      ...dynamicList,
      {
        label: '总件数', prop: 'defaultUnitAmount', width: '60px',
      },
      { label: '整箱数', prop: 'bigUnitAmount', width: '60px' },
      { label: '拆零数', prop: 'smallUnitAmount', width: '60px' },
      { label: '总吨数', prop: 'totalWeight', width: '60px' },
      { label: '不足件数', prop: 'lackSmallUnitAmount', width: '60px' },
      { label: '装车件数', prop: 'transportSmallUnitAmount', width: '60px' },
    ];
  },
};
