<template>
  <div v-loading="loading.featchDate" class="print-page">
    <div class="print-btn">
      <PrintButton
        v-model="temperatureCode"
        :type="defaultScreen?TEMPERATURE_LAYER_BUTTON.type:PRINT_BUTTON.type"
        :temperature-list="TEMPERAYURE_LIST"
        :before-print="beforePrint"
        @change="temperatureChanged"
      />
      <div v-if="defaultScreen" class="print-btn">
        <div class="margin-24">
          选择批属性:
          <el-select
            v-model="selectBatchAttributes"
            multiple
            :multiple-limit="2"
            placeholder="请选择"
          >
            <el-option
              v-for="item in batchAttributes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <p class="margin-24">
          说明:最多可选择2个批属性。
        </p>
      </div>
    </div>
    <div v-for="(printList, printNumber) in prints" :key="printNumber">
      <component
        :is="printList.component"
        :batch-attributes="dynamicList"
        :print-list="printList"
      />
    </div>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';

import Moment from '@/utils/moment';
import { YMD, YMDHMS } from '@/constant/timeFormat';
import { TEMPERATURE_LAYER, TemperatureLayerType } from '@/constant/temperatureLayer';
import PrintButton from '../components/PrintButton/PrintButton.vue';
import { TEMPERATURE_LAYER_BUTTON, PRINT_BUTTON } from '../components/PrintButton/config';
import Normal from './normal/index.vue';
import Customized1 from './customized1/index.vue';
import config from './config';
import fileds from './fileds';
import {
  waveDeliverDetails, waveOrderPrintCount, batchFieldList, outboundDetails,
} from './api';

// 打印类型
const printType = 'DELIVER_PRINT';
const unfreezeCode = 'JD';
export default {
  name: 'OutboundOrderNormal',
  components: {
    PrintButton,
    Normal,
    Customized1,
  },
  mixins: [loadingMixin],
  data() {
    return {
      TEMPERATURE_LAYER_BUTTON,
      PRINT_BUTTON,
      config,
      fileds,
      TEMPERATURE_LAYER,
      TemperatureLayerType,
      batchAttributes: [{ value: 'productDate', label: '生产日期', type: 'date' }], // 批属性
      selectBatchAttributes: ['productDate'], // 默认选中生产日期
      temperatureCode: null,
      waveOrderIds: [], // 出库单ids
      prints: [], // 批量打印数据
      mergeTable: [],
      loading: {
        featchDate: false,
      },
    };
  },
  computed: {

    dynamicList() {
      return this.selectBatchAttributes.map((item) => this.batchAttributes.find(
        (attr) => attr.value === item,
      ));
    },
    TEMPERAYURE_LIST() {
      const list = this.TEMPERATURE_LAYER.map(({ name, layer }) => ({
        temperatureLayerCode: layer,
        temperatureLayerName: name,
      }));
      // 应产品要求，打印单筛选去除解冻选项
      const temperatureLayerList = list
        .filter((item) => item.temperatureLayerCode !== unfreezeCode);
      temperatureLayerList.unshift({ temperatureLayerCode: null, temperatureLayerName: '全部' });
      return temperatureLayerList;
    },
    defaultScreen() {
      return this.prints.some((item) => item.component === config.DEFAULT);
    },
  },
  created() {
    const { waveOrderIds } = this.$route.query;
    if (Array.isArray(waveOrderIds)) {
      this.waveOrderIds = waveOrderIds;
    } else {
      this.waveOrderIds = [waveOrderIds];
    }
    this.init();
  },
  methods: {
    async init() {
      this.getBatchAttributes();
      this.featchDate();
    },
    // 获取批次字段
    async getBatchAttributes() {
      const resolve = await batchFieldList();
      resolve.forEach((item) => {
        if (!this.batchAttributes.some((batch) => batch.value === item.fieldCode)) {
          this.batchAttributes.push({
            value: item.fieldCode, label: item.fieldName, type: item.component,
          });
        }
      });
    },
    async featchDate() {
      this.prints = [];
      // 派摩
      if (this.$store.state.user.accountInfo.tenantId === 'T10190258060') {
        const result = await outboundDetails(
          { waveOrderIds: this.waveOrderIds, temperatureLayer: this.temperatureCode },
        );
        this.prints = result.map((item) => (
          {
            ...item,
            printTime: Moment.format(new Date(), YMDHMS),
            component: this.config.PRINT_PAIMO,
            temperatureLayer: this.temperatureLayerBuild(item.orderGoodsDetails),
          }
        ));
      } else {
        const result = await waveDeliverDetails(
          { waveOrderIds: this.waveOrderIds, temperatureLayer: this.temperatureCode },
        );
        this.prints = result.map((item) => (
          {
            ...item,
            planedDeliverTime: Moment.format(item.planedDeliverTime, YMD),
            beginLoadingTime: Moment.format(item.beginLoadingTime, YMD),
            endLoadingTime: Moment.format(item.endLoadingTime, YMD),
            printTime: Moment.format(new Date(), YMDHMS),
            totalWeight: item.totalWeight.toFixed(3),
            component: this.config.DEFAULT,
            stationName: item.deliverOrders[0]?.stationName,
            temperatureLayer: this.temperatureLayerBuild(item.goodsDetails),
          }
        ));
      }
    },
    temperatureLayerBuild(row) {
      const temperatureLayerList = new Set(row
        .map((it) => this.TemperatureLayerType[it.temperatureLayer]));
      return [...temperatureLayerList].join(', ');
    },
    temperatureChanged() {
      this.init();
    },
    async beforePrint() {
      const data = {
        waveOrderIds: this.waveOrderIds,
        printType,
      };
      await waveOrderPrintCount(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  max-width: 1123px;
  margin: 0 auto;
}

.print-btn {
  display: flex;
  align-items: baseline;

  .margin-24 {
    margin-left: 24px;
  }
}

@media print {
  .print-btn {
    display: none;
  }
}
 </style>
