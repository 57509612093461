<template>
  <div class="print-list">
    <div
      v-for="(table, tableNumber) in tables"
      :key="tableNumber"
      :class="{
        'print-contanier': tableNumber !== tables.length - 1,
        'print-padding': tableNumber !== 0
      }"
    >
      <print-header
        :title="config"
        :show-ext="false"
        :hide-logo="true"
        :barcode="printList.ladingOrderNo"
        :print-config="printList"
      />
      <div class="print-public">
        <div
          v-for="(row, index) in fileds.PUBLIC"
          :key="index"
          class="row"
        >
          <div
            v-for="item in row"
            :key="item.value"
            class="col"
            :style="{'flex': item.width}"
          >
            <p>
              {{ item.label }}
              <span :style="item.textStyle">{{ printList[item.value] }}</span>
            </p>
          </div>
        </div>
      </div>
      <print-table
        class="table"
        :headers="tableHeaders"
        :list="table"
      />
    </div>

    <div class="footer-sign">
      <div class="footer-sign-row">
        <div class="footer-sign-col" :style="{'flex': 1}">
          备注
        </div>
        <div class="footer-sign-col" :style="{'flex': 3}" />
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con">
            车厢清洁情况
          </div>
          <div class="footer-sign-con">
            车厢预冷温度
          </div>
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con" :style="{'flex': 1}" />
          <div class="footer-sign-con" :style="{'flex': 1}" />
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con">
            实发客户板数
          </div>
          <div class="footer-sign-con">
            实发穿梭板数
          </div>
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con">
            收货签名
          </div>
          <div class="footer-sign-con" />
        </div>
      </div>

      <div class="footer-sign-row">
        <div class="footer-sign-col" :style="{'flex': 1}">
          现管安排工作人员
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con">
            场管
          </div>
          <div class="footer-sign-con" />
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con">
            仓管
          </div>
          <div class="footer-sign-con" />
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con">
            高叉
          </div>
          <div class="footer-sign-con" />
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con">
            搬运组
          </div>
          <div class="footer-sign-con" />
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          <div class="footer-sign-con">
            装卸口
          </div>
          <div class="footer-sign-con" />
        </div>
        <div class="footer-sign-col" :style="{'flex': 1}">
          搬运实际工作量
        </div>
        <div class="footer-sign-col" :style="{'flex': 2}" />
      </div>

      <div class="footer-sign-row">
        <div :style="{'flex': 1}">
          单证员:
        </div>
        <div :style="{'flex': 1}">
          现管:
        </div>
        <div :style="{'flex': 1}">
          提货人:
        </div>
        <div :style="{'flex': 1}">
          场管:
        </div>
        <div :style="{'flex': 1}">
          仓管:
        </div>
        <div :style="{'flex': 1}">
          搬运组:
        </div>
        <div :style="{'flex': 1}">
          盖章
        </div>
      </div>
      <div class="footer-sign-row">
        <div :style="{'flex': 1}" />
        <div :style="{'flex': 1}">
          第一联(白色):单证联;
        </div>
        <div :style="{'flex': 1}">
          第一联(红色):运营联;
        </div>
        <div :style="{'flex': 1}">
          第一联(黄色):搬运联;
        </div>
        <div :style="{'flex': 1}">
          第一联(绿色):客户联;
        </div>
        <div :style="{'flex': 1}" />
      </div>
    </div>
  </div>
</template>

<script>
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';

import { PRINT_BUTTON } from '../../components/PrintButton/config';
import config from './config';
import fileds from './fileds';

export default {
  name: 'DeliveryListNormalNor',
  components: {
    PrintHeader,
    PrintTable,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
    batchAttributes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      PRINT_BUTTON,
      config,
      fileds,
    };
  },
  computed: {
    tables() {
      return this.formatTable();
    },
    tableHeaders() {
      return fileds.getTableHeaders(this.batchAttributes);
    },
  },
  methods: {
    formatTable() {
      const tables = [];
      let tablePage = 0;
      const tableRowsCount = config.maxRowsNumber;
      if (this.printList.goodsDetails.length === 0) {
        tablePage += 1;
        tables.push([]);
      }
      let totalDefaultUnitAmount = 0;
      let bigUnitAmount = 0;
      let totalWeight = 0;
      let smallUnitAmount = 0;
      let lackSmallUnitAmount = 0;
      let transportSmallUnitAmount = 0;
      this.printList.goodsDetails.forEach((it, index) => {
        totalDefaultUnitAmount += it.defaultUnitAmount * 1000;
        bigUnitAmount += it.bigUnitAmount * 1000;
        totalWeight += it.totalWeight.toFixed(3) * 1000;
        smallUnitAmount += it.smallUnitAmount * 1000;
        lackSmallUnitAmount += it.lackSmallUnitAmount * 1000;
        transportSmallUnitAmount += it.transportSmallUnitAmount * 1000;
        const item = {
          ...it,
          ...it.batchAttributes,
          totalWeight: it.totalWeight.toFixed(3),
        };
        if (index === tableRowsCount * tablePage) {
          tablePage += 1;
          tables.push([]);
        }
        tables[tablePage - 1].push(item);
      });
      const row = {
        goodsName: '合计',
        defaultUnitAmount: totalDefaultUnitAmount / 1000,
        bigUnitAmount: bigUnitAmount / 1000,
        totalWeight: totalWeight / 1000,
        smallUnitAmount: smallUnitAmount / 1000,
        lackSmallUnitAmount: lackSmallUnitAmount / 1000,
        transportSmallUnitAmount: transportSmallUnitAmount / 1000,
      };
      tables[tablePage - 1].push(row);
      return tables;
    },
  },
};
</script>
<style lang="scss" scoped>
.print-list {
  page-break-after: always;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }

    .increase-size {
      font-size: 20px;
    }
  }
}

.row.border {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  .col {
    height: 28px;
    padding: 2px 0;
    border-right: 1px solid #000;
  }
}

.table {
  border-collapse: collapse;
}

.footer-sign {
  .footer-sign-row {
    display: flex;

    .footer-sign-col {
      text-align: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #000;
      border-bottom: 1px solid #000;
      border-left: 1px solid #000;

      .footer-sign-con {
        flex: 1;
        border-bottom: 1px solid #000;
      }
    }
  }

  p {
    padding: 0 10px;
    font-size: 14px;
  }
}

.distribution-information {
  border: 2px solid #000;
}

@media print {
  // 送货单顶部边距需要增加。因为实际打印时需要再设置边距，然后头部有部分信息展示不全。
  $paddingTop: 10px;

  .print-list {
    padding-top: $paddingTop;
  }

  .print-contanier {
    padding-bottom: 0;
    page-break-after: always;
  }

  .print-padding {
    padding-top: $paddingTop;
  }
}
 </style>
