<template>
  <div class="print-list">
    <div
      v-for="(table, tableNumber) in tables"
      :key="tableNumber"
      :class="{
        'print-contanier': tableNumber !== tables.length - 1,
        'print-padding': tableNumber !== 0
      }"
    >
      <print-header
        :title="config"
        :show-ext="false"
        :hide-logo="true"
        :hide-barcode="true"
        :print-config="printList"
      />
      <div class="print-public">
        <div
          v-for="(row, index) in fileds.PUBLIC"
          :key="index"
          class="row"
        >
          <div
            v-for="item in row"
            :key="item.value"
            class="col"
            :style="{'flex': item.width}"
          >
            <p>
              {{ item.label }}
              <span :style="item.textStyle">{{ printList[item.value] }}</span>
            </p>
          </div>
        </div>
      </div>
      <print-table
        class="table"
        :headers="tableHeaders"
        :list="table"
      />
    </div>

    <div class="footer-sign">
      <div class="footer-sign-tips">
        温馨提示内容:收货时,请核对商品名称、数量   是否破损、如有疑问当日与我司联系,逾期视为收货正常,出现收货问题,我们将不予受理,请谅解。
      </div>

      <div class="footer-sign-row">
        <div :style="{'flex': 1}">
          分拣:
        </div>
        <div :style="{'flex': 1}">
          复核:
        </div>
        <div :style="{'flex': 1}">
          打包封装:
        </div>
        <div :style="{'flex': 1}">
          送货:
        </div>
      </div>
      <div class="footer-sign-row">
        <div :style="{'flex': 1}">
          客服电话:
        </div>
        <div :style="{'flex': 1}">
          收货人签字:
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import priceChange from '@/utils/priceChange';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';

import { PRINT_BUTTON } from '../../components/PrintButton/config';
import config from './config';
import fileds from './fileds';

export default {
  name: 'DeliveryListNormalNor',
  components: {
    PrintHeader,
    PrintTable,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
    batchAttributes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      PRINT_BUTTON,
      config,
      fileds,
    };
  },
  computed: {
    tables() {
      return this.formatTable();
    },
    tableHeaders() {
      return fileds.getTableHeaders(this.batchAttributes);
    },
  },
  methods: {
    formatTable() {
      const tables = [];
      let tablePage = 0;
      const tableRowsCount = config.maxRowsNumber;
      if (this.printList.orderGoodsDetails.length === 0) {
        tablePage += 1;
        tables.push([]);
      }
      let totalDefaultUnitAmount = 0;
      let totalPrice = 0;
      let wholeSalePrice = 0;
      let presentPrice = 0;
      let defaultUnitAmount = 0;
      let discountRate = 0;
      this.printList.orderGoodsDetails.forEach((it, index) => {
        totalDefaultUnitAmount += it.goodsPrice * 1000;
        totalPrice += it.totalPrice * 1000;
        wholeSalePrice += it.wholeSalePrice * 1000;
        presentPrice += it.presentPrice * 1000;
        defaultUnitAmount += it.defaultUnitAmount * 1000;
        discountRate += it.discountRate * 1000;
        const item = {
          ...it,
          ...it.batchAttributes,
          wholeSalePrice: it.wholeSalePrice,
        };
        if (index === tableRowsCount * tablePage) {
          tablePage += 1;
          tables.push([]);
        }
        tables[tablePage - 1].push(item);
      });
      const row = {
        shipperGoodsCode: '合计',
        goodsName: priceChange(totalPrice / 1000), // 总价
        goodsPrice: totalDefaultUnitAmount / 1000, // 单价
        defaultUnitAmount: defaultUnitAmount / 1000, // 数量
        totalPrice: totalPrice / 1000, // 金额
        wholeSalePrice: wholeSalePrice / 1000, // 批发价
        presentPrice: presentPrice / 1000, // 赠送金额
        discountRate: discountRate / 1000, // 扣率
      };
      tables[tablePage - 1].push(row);
      return tables;
    },
  },
};
</script>
<style lang="scss" scoped>
.print-list {
  page-break-after: always;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }

    .increase-size {
      font-size: 20px;
    }
  }
}

.row.border {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  .col {
    height: 28px;
    padding: 2px 0;
    border-right: 1px solid #000;
  }
}

.table {
  border-collapse: collapse;
}

.footer-sign {
  .footer-sign-tips {
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  .footer-sign-row {
    display: flex;
  }

  p {
    padding: 0 10px;
    font-size: 14px;
  }
}

.distribution-information {
  border: 2px solid #000;
}

@media print {
  // 送货单顶部边距需要增加。因为实际打印时需要再设置边距,然后头部有部分信息展示不全。
  $paddingTop: 10px;

  .print-list {
    padding-top: $paddingTop;
  }

  .print-contanier {
    padding-bottom: 0;
    page-break-after: always;
  }

  .print-padding {
    padding-top: $paddingTop;
  }
}
 </style>
