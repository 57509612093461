import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:专为穿梭提供的拣货信息接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17023
 */
export function waveDeliverDetails(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/chuan_suo/wave_deliver/details', data);
}

/**
 * @description:出库单明细
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19267
 */
export function outboundDetails(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order_print/order_details', data);
}

/**
 * @description:打印次数
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17647
 */
export function waveOrderPrintCount(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/print_count', data);
}

/**
 * @description:批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15013
 */
export function batchFieldList() {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/list', {});
}
